import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'

const portfolio = () => (
    <div>
        <SEO title="Projects" />
        <Layout>
            <h2>Projects</h2>
            <h3>Current Projects</h3>
            <br />
            <div>
                <p>
                    As my capstone project for my undergraduate degree through Arizona State University,
                    I and four other team members are working on improving and deploying an Iron Meteorite Database System by May 2020,
                    which was previously started by another team of five students in the 2018-2019 academic year,
                    for the <a href="https://www.jpl.nasa.gov/missions/psyche/">NASA Psyche Mission - Journey to a Metal World</a>.
                </p>
                <p>
                    The Psyche Mission is to send an orbiter to the asteroid 16 Psyche located between Mars and Jupiter.
                    This asteroid is a metallic asteroid thought to be the remaining core of a protoplanet torn assunder
                    during the creation of our solar system. The Iron Meteorite Database System will provide a means of scraping scientific journals
                    containing data on trace elements within iron and other meteorites,
                    putting the data into a database, and then making the database accessible via a single page web application.
                </p>
                <p>
                    My primary role in this project has been leading the migration of the web application to using React.js for a single page application.
                    I have also taken part in debugging and improving overall functionality, which includes using technologies such as Docker,
                    Node.js with Express.js, PostgreSQL, and the Python Natural Language Toolkit (NLTK).
                </p>
            </div>
        </Layout>
    </div>
)

export default portfolio;
